import React, { useState, useEffect } from 'react';
import LatePayment from '../components/LatePayment.js';
import OnePayment from '../components/OnePayment.js';
import RecurringPayment from '../components/RecurringPayment.js';
import { jwtDecode } from 'jwt-decode';
import './Payment.css'

const Payment = () => {
    const [parks, setParks] = useState(false);
    const [buildingId, setBuildingId] = useState(null);

    useEffect(() => {
        //Give our page stripe access
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/buy-button.js';
        script.async = true;
        document.body.appendChild(script);
        
        const token = localStorage.getItem('cathyToken');
        if (token) {
            const decodedToken = jwtDecode(token);
            //console.log(JSON.stringify(decodedToken));
            setParks(decodedToken.user.parks);
            setBuildingId(decodedToken.user.buildingId);
        }
        //console.log("Parks: "+parks);
        //console.log("BuildingId: "+buildingId)

        return () => {document.body.removeChild(script); /*Clean up on component on unmount*/ };
    }, [parks, buildingId]);

    const renderPaymentMethod = () => {
        const now = new Date();
        const late = (now.getDate() > 25 || now.getDate() < 7);

        //console.log("Parks and building in renderPayment: "+parks+":"+buildingId);
        if (late) {
            return <LatePayment parks={parks} buildingId={buildingId} />;
        } else {
            return (
                <div className='payment-content'>
                    <RecurringPayment parks={parks} buildingId={buildingId} />
                    <br />
                    <br />
                    <OnePayment parks={parks} buildingId={buildingId} />
                    <br/>
                </div> 
                /*
                <div className='payment-content'>
                    <br />
                    <OnePayment parks={parks} buildingId={buildingId} />
                    <br/>
                </div>
                */
            );
        }
    };

    if(parks === null || buildingId === null) return <p>Loading...</p>

    return (
        <div className="payment-container">
            <h1 className='title'>Online payment</h1>
            <br />
            {renderPaymentMethod()}
        </div>
    );
};

export default Payment;
