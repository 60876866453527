import React from 'react';

const RecurringPayment = ({ parks, buildingId }) => {

       const getButtonId = () => {
              if (!parks) {
                     switch (buildingId) {
                            case 1:
                                   return "buy_btn_1PsYWwP39O7fMWAZsW0B2ETo";
                            case 2:
                                   return "buy_btn_1PsYYtP39O7fMWAZHNqMD84d";
                            case 3:
                                   return "buy_btn_1PsYRzP39O7fMWAZBe3pYHC7";
                            default:
                                   //console.log("Error: got "+buildingId);
                                   return "";
                     }
              } else {
                     switch (buildingId) {
                            case 1:
                                   //For DEVELOPEMENT
                                   //return "buy_btn_1PtHj1P39O7fMWAZqPuED7vy";
                                   //For PRODUCTION
                                   return "buy_btn_1PsXuYP39O7fMWAZcDIHgGrX";
                            case 2:
                                   return "buy_btn_1PsXqhP39O7fMWAZswvK4Sfx";
                            case 3:
                                   return "buy_btn_1PsYVKP39O7fMWAZGz0VS7ht";
                            default:
                                   //console.log("Error: got "+buildingId);
                                   return "";
                     }
              }
       };

       return (
              <stripe-buy-button
                     buy-button-id={getButtonId()}
                     publishable-key={process.env.REACT_APP_STRIPE_KEY}
              >
              </stripe-buy-button>
       );
};

export default RecurringPayment;


/*
//Spyglas R rep
buy_btn_1PsYWwP39O7fMWAZsW0B2ETo
//Spyglas RP rep
buy_btn_1PsXuYP39O7fMWAZcDIHgGrX




//Normas R rep
buy_btn_1PsYYtP39O7fMWAZHNqMD84d
//Normas RP rep
buy_btn_1PsXqhP39O7fMWAZswvK4Sfx




//Single R Tree rep
buy_btn_1PsYRzP39O7fMWAZBe3pYHC7
//Single RP Tree rep
buy_btn_1PsYVKP39O7fMWAZGz0VS7ht
*/