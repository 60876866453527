// frontend/src/components/Navbar.js

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [token, setToken] = useState(null);
    const menuRef = useRef(null);
    const hamRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const deleteToken = () => {
        localStorage.removeItem('cathyToken');
        toggleMenu();
    }

    const handleClickOutside = (event) => {
        setToken(!!localStorage.getItem('cathyToken'));
        if (menuRef.current && !menuRef.current.contains(event.target) && !hamRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {document.removeEventListener('mousedown', handleClickOutside);};
    }, []);

    return (
        <nav className="navbar">
            <button ref={hamRef} className="menu-button" onClick={toggleMenu}>☰</button>
            <div className="navbar-center">
                <p className='standard-title'>
                    Cathy's Computer {(window.innerWidth > 1440) && '- A mobile first application'}
                </p>
            </div>
            <img src="/assets/TypewriterComputer.png" alt="Cathy's Computer Logo" className="navbar-image" />
            <ul ref={menuRef} className={`menu${menuOpen ? ' open' : ''}`}>
                {token && <li><Link to="/payment" onClick={toggleMenu}>Pay Online</Link></li>}
                {token && <li><Link to="/maintenance" onClick={toggleMenu}>Request Maintenance</Link></li>}
                {token && <li><Link to="/notice" onClick={toggleMenu}>Notices</Link></li>}
                <li><Link to="/contract" onClick={toggleMenu}>Contracts</Link></li>
                {token && <li><Link to="/profile" onClick={toggleMenu}>Profile</Link></li>}
                <li><Link to="/about" onClick={toggleMenu}>About / FAQ</Link></li>
                <li><Link to="/support" onClick={toggleMenu}>Support</Link></li>
                {!token && <li><Link to="/login" onClick={toggleMenu}>Login</Link></li>}
                {token && <li><Link to="/login" onClick={deleteToken}>Logout</Link></li>}
            </ul>
        </nav>
    );
    
};

export default Navbar;
