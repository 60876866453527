import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import { jwtDecode } from 'jwt-decode';

import './styles/variables.css';
import './App.css';

import Private from './components/Private';
import Contract from './pages/Contract';
import Maintenance from './pages/Maintenance';
import Notice from './pages/Notice';
import Payment from './pages/Payment';
import Profile from './pages/Profile';
import FAQ from './pages/FAQ';
import Support from './pages/Support';
import TaC from './pages/TaC';

import AuthForm from './pages/Authform';
import Navbar from './components/Navbar';

const App = () => {

    const handleToken = (data) => {
        const token = data.token;
        localStorage.setItem('cathyToken', token);
        //console.log("JWT Token:", token);
        //const decodedToken = jwtDecode(token);
        //console.log(JSON.stringify(decodedToken));
    }

    const handleLoginSuccess = (data) => {
        handleToken(data);
    };

    const handleUpdateSuccess = (data) => {
        handleToken(data);
    };

    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<AuthForm onSuccess={handleLoginSuccess} />} />
                <Route path="/login" element={<AuthForm onSuccess={handleLoginSuccess} />} />
                <Route path="/contract" element={<Contract />} />
                <Route path="/maintenance" element={<Private component={Maintenance} />} />
                <Route path="/notice" element={<Private component={Notice} />} />
                <Route path="/payment" element={<Private component={Payment} />} />
                <Route path="/profile" element={<Private component={() => <Profile onSuccess={handleUpdateSuccess} />} />} />
                <Route path="/about" element={<FAQ />} />
                <Route path="/support" element={<Support />} />
                <Route path="/termsAndConditions" element={<Private component={TaC} />} />
            </Routes>
        </Router>
    );
};

export default App;
