import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import './Profile.css';

const Profile = ({ onSuccess }) => {
    const [editing, setEditing] = useState(false);
    const [userId, setUserId] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const [prevFormData, setPrevFormData] = useState({
        email: '',
        phone: '',
        buildingId: '',
        apt: '',
        parks: '',
        selling: '',
        currentPassword: '',
        newPassword: '',
    });

    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        buildingId: '',
        apt: '',
        parks: '',
        selling: '',
        currentPassword: '',
        newPassword: '',
    });

    const buildings = [
        { id: '1', name: 'Spyglas' },
        { id: '2', name: 'Normas' },
        { id: '3', name: 'Single Tree' },
    ];

    useEffect(() => {
        const token = localStorage.getItem('cathyToken');
        if (token) {
            const decodedToken = jwtDecode(token);
            setUserId(decodedToken.user.id);
        }
    
        const fetchFormData = async () => {
            if (userId) {
                try {
                    const endpoint = `${process.env.REACT_APP_API_URL}/api/user/${userId}`;
                    const response = await axios.get(endpoint);
                    
                    const fetchedData = {
                        email: response.data.email,
                        phone: response.data.phone,
                        buildingId: response.data.buildingId,
                        apt: response.data.apt,
                        parks: response.data.parks,
                        selling: response.data.selling,
                        currentPassword: '',
                        newPassword: '',
                    };
                    
                    setFormData(fetchedData);
                    setPrevFormData(fetchedData);
                } catch (err) {
                    setSuccess('');
                    setError('Error in retrieval: ' + err.message);
                }
            }
        };
    
        fetchFormData();
    }, [userId]);
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        setError('');
        setSuccess('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.currentPassword) {
            setError('Please enter your password to save changes.');
            return;
        }
        
        //console.log("FORM DATA: "+JSON.stringify(formData));
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}/api/user/${userId}`;
            const response = await axios.put(endpoint, formData);
            
            formData.currentPassword = '';
            formData.newPassword = '';
            setPrevFormData(formData);
            setEditing(false);
            setError('');
            setSuccess('Successfully updated.');
            onSuccess(response.data);
        } catch (err) {
            setSuccess('');
            if(err.status !== 400){
                setError('Failed to update: '+err.message);
            } else {
                setError('Failed to update: "Invalid password"');
            }
        }
    };

    const handleEdit = () => {
        setEditing(true);
        setError('');
        setSuccess('');
    };

    const handleCancel = () => {                    
        setFormData(prevFormData);
        setEditing(false);
        setError('');
        setSuccess('No changes made.');
    };

    if (!userId || !formData.email) return <p>Loading...</p>;

    return (
        <div className="profile-container">
            <br />
            <br />
            <br />
            <div className="frm">
                <h2>{editing ? 'Edit' : 'Profile'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            disabled={!editing}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            disabled={!editing}
                        />
                    </div>
                    {!editing && (
                        <>
                    <div className="form-group">
                        <label htmlFor="buildingId">Building</label>
                        <select
                            id="buildingId"
                            name="buildingId"
                            value={formData.buildingId}
                            onChange={handleChange}
                            disabled={!editing}
                            required
                        >
                            <option value="">Select Building</option>
                            {buildings.map((building) => (
                                <option key={building.id} value={building.id}>
                                    {building.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="apt">Apt</label>
                        <input
                            type="text"
                            id="apt"
                            name="apt"
                            value={formData.apt}
                            onChange={handleChange}
                            disabled={!editing}
                            required
                        />
                    </div>
                    </>
                    )}
                    {editing && (
                        <>
                            <div className="form-group">
                                <label htmlFor="currentPassword">Current Password</label>
                                <input
                                    type="password"
                                    id="currentPassword"
                                    name="currentPassword"
                                    value={formData.currentPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="newPassword">New Password (optional)</label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    value={formData.newPassword}
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    )}
                    <div className="form-group">
                        <div className="label-checkbox-container">
                            <label htmlFor="parks">I'm paying for parking</label>
                            <input
                                type="checkbox"
                                id="parks"
                                name="parks"
                                checked={formData.parks}
                                onChange={(e) => setFormData({ ...formData, parks: e.target.checked })}
                                disabled={!editing}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label-checkbox-container">
                            <label htmlFor="selling">I'm selling my contract</label>
                            <input
                                type="checkbox"
                                id="selling"
                                name="selling"
                                checked={formData.selling}
                                onChange={(e) => setFormData({ ...formData, selling: e.target.checked })}
                                disabled={!editing}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        {editing ? (
                            <>
                                <button className="btn" type="submit">Save</button>
                                <br />
                                <br />
                                <button className="btn" type="button" onClick={handleCancel}>Cancel</button>
                            </>
                        ) : (
                            <button className="btn" type="button" onClick={handleEdit}>Edit</button>
                        )}
                    </div>
                </form>
                {success && <p className="success-message">{success}</p>}
                {error && <p className="error-message">{error}</p>}
            </div>
            <br/>
            <br/>
        </div>
    );
};

export default Profile;
