import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
import './Notice.css';

const Notice = () => {
    const [notices, setNotices] = useState([]);
    const [message, setMessage] = useState('');
    const [expandedRequest, setExpandedRequest] = useState(null); // State for accordion

    useEffect(() => {
        const fetchNotices = async () => {
            try {
                const token = localStorage.getItem('cathyToken');
                const decodedToken = jwtDecode(token);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notice/building/${decodedToken.user.buildingId}`);
                
                if (response.data.message) {
                    setMessage(response.data.message);
                } else {
                    setNotices(response.data);
                }
            } catch (err) {
                if (err.response.status !== 404) {
                    setMessage('Error fetching notices: ' + err.message);
                    //console.log(message);
                }
            }
        };

        fetchNotices();
    }, [message]);

    const toggleRequest = (index) => {
        setExpandedRequest(expandedRequest === index ? null : index);
    };

    return (
        <div>
        <div className="notices-container">
            <br/>
            <h1>Building Notices</h1>
        </div>
        <ul className="my-accordion">
            {notices.length > 0 ? (
                notices.map((notice, index) => (
                    <li key={index} className="my-accordion-item">
                        <div className="my-accordion-title" onClick={() => toggleRequest(index)}>{notice.title}, {notice.dates}</div>
                        {expandedRequest === index && (
                            <div className="my-accordion-content" dangerouslySetInnerHTML={{ __html: notice.content.replace(/(?:\r\n|\r|\n)/g, '<br/>') }}>
                            </div>
                        )}
                    </li>
                ))
            ) : (
                <div >
                    <li key={0} className="my-accordion-item">
                        <div className="my-accordion-title">No notices found</div>
                        <div className="my-accordion-content"><p>{<> We don't have any notices for your building. Please <Link to="/support">send</Link> us a pic of what you got and we'll upload it for everyone! </>}</p></div>
                    </li>
                    
                </div>
            )}
        </ul>
        </div>
    );
};

export default Notice;
