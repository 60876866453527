import React from 'react';

const SpyglasContractPages = () => {
    return (
        <div className="contract-pages">
            <img src={`/assets/contracts/spyglas/page1.jpg`} alt="Contract Page 1" />
            <img src={`/assets/contracts/spyglas/page2.jpg`} alt="Contract Page 2" />
            <img src={`/assets/contracts/spyglas/page3.jpg`} alt="Contract Page 3" />
            <img src={`/assets/contracts/spyglas/page4.jpg`} alt="Contract Page 4" />
            <img src={`/assets/contracts/spyglas/page5.jpg`} alt="Contract Page 5" />
            <img src={`/assets/contracts/spyglas/page6.jpg`} alt="Contract Page 6" />
            <img src={`/assets/contracts/spyglas/page7.jpg`} alt="Contract Page 7" />
            <img src={`/assets/contracts/spyglas/page8.jpg`} alt="Contract Page 8" />
            <img src={`/assets/contracts/spyglas/page9.jpg`} alt="Contract Page 9" />
            <img src={`/assets/contracts/spyglas/page10.jpg`} alt="Contract Page 10" />
            <img src={`/assets/contracts/spyglas/page11.jpg`} alt="Contract Page 11" />
        </div>
    );
}

export default SpyglasContractPages;