// frontend/src/components/Private.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Private = ({ component: Component }) => {
    const token = localStorage.getItem('cathyToken');
    const isAuthenticated = !!token && jwtDecode(token).user.id;
    return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export default Private;
