import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import './Support.css';

const Support = () => {
    const [quote, setQuote] = useState('');
    const [cathyStatus, setCathyStatus] = useState('out');
    const [lastUpdated, setLastUpdated] = useState('');

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                const response = await fetch('/quotes.txt');
                const quotes = await response.json();
                const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
                setQuote(randomQuote);
            } catch (err) {
                console.error('Error fetching quotes:', err);
            }
        };

        fetchQuotes();

        // Determine Cathy's status
        const updateCathyStatus = () => {
            const now = new Date();
            const day = now.getDay();
            const hour = now.getHours();
            const minute = now.getMinutes();

            if (
                (day > 0 && day < 6) && (
                (hour > 9 && hour < 12) ||
                (hour === 9 && minute >= 0) ||
                (hour === 12 && minute === 0) ||
                (hour > 13 && hour < 17) ||
                (hour === 13 && minute >= 30) ||
                (hour === 17 && minute === 0))
            ) {
                setCathyStatus('in');
            } else {
                setCathyStatus('out of');
            }

            setLastUpdated(now.toLocaleTimeString());
        };

        updateCathyStatus();
    }, []);

    return (
        <div className="resources-container">
                <h2 className='quote-author'>{quote.a}</h2>
                {quote ? (
                    <p dangerouslySetInnerHTML={{ __html: quote.q }} />
                ) : (
                    <p>Loading quote...</p>
                )}
                <hr />
                <h2>Management</h2>
                <p>Please make a record of everything. Get and keep written receipts whenever possible, and since Utah is a <Link to="https://www.rcfp.org/reporters-recording-guide/utah/">one-party-consent</Link> state, you should record conversations with your landlord. <Link to="https://och.byu.edu/">BYU housing</Link> is your friend and knows this management well. They can also help with maintenance issues or contract questions / disputes.</p>

                <h2>Your Contract</h2>
                <p>"Can my landlord do X?" or "Am I reading Y in the contract right?" are questions we get often. We want to help you if you have questions or concerns! While we aren't the authoritative source (see Cathy), contact us for an extra set of eyes.</p>
                
                <h2>Links</h2>
                <p><Link to='/termsAndConditions'>Terms and Conditions</Link>, please read them.</p>
                
                <h2>Contacts</h2>
                <p>
                    Text or call us: 385-392-5785<br/>
                    Email: cathysfirstcomputer@gmail.com<br />
                    <br />
                    BYU housing: 801-422-1513<br/>
                    Cathy, pri: 801-358-5017<br/>
                    Cathy, sec: 801-356-7519<br/>
                    Shannon, cleaning: 856-345-3557<br/>
                </p>
                <h2>Cathy's Hours</h2>
                <p>{<>
                    Cathy is currently {cathyStatus} office.<br/>
                    Updated: {lastUpdated}<br/>
                </>}</p>
                <hr />
        </div>
    );
};

export default Support;
