import React from 'react';

const NormaContractPages = () => {
    return (
        <div className="contract-pages">
            <img src={`/assets/contracts/normas/page1.jpg`} alt="Contract Page 1" />
            <img src={`/assets/contracts/normas/page2.jpg`} alt="Contract Page 2" />
            <img src={`/assets/contracts/normas/page3.jpg`} alt="Contract Page 3" />
            <img src={`/assets/contracts/normas/page4.jpg`} alt="Contract Page 4" />
            <img src={`/assets/contracts/normas/page5.jpg`} alt="Contract Page 5" />
            <img src={`/assets/contracts/normas/page6.jpg`} alt="Contract Page 6" />
            <img src={`/assets/contracts/normas/page7.jpg`} alt="Contract Page 7" />
            <img src={`/assets/contracts/normas/page8.jpg`} alt="Contract Page 8" />
            <img src={`/assets/contracts/normas/page9.jpg`} alt="Contract Page 9" />
            <img src={`/assets/contracts/normas/page10.jpg`} alt="Contract Page 10" />
            <img src={`/assets/contracts/normas/page11.jpg`} alt="Contract Page 11" />
        </div>
    );
}

export default NormaContractPages;