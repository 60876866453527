import React from 'react';

const OnePayment = ({ parks, buildingId }) => {

    const getButtonId = () => {
        if (!parks) {
            switch (buildingId) {
                case 1:
                case "1":
                    return "buy_btn_1PsYVmP39O7fMWAZlX3QY25p"
                case 2:
                    return "buy_btn_1PsYXeP39O7fMWAZfXIsjFEs"
                case 3:
                    return "buy_btn_1PsYQNP39O7fMWAZW5i1dZ8d"
                default:
                    //console.log("Error: got " + buildingId);
                    return "";
            }
        } else {
            switch (buildingId) {
                case 1:
                case "1":
                    //For DEVELOPEMENT
                    //return "buy_btn_1PtHf2P39O7fMWAZ5Tfq2mzy"
                    //For PRODUCTION
                    return "buy_btn_1PsYW6P39O7fMWAZJkemFBAe"
                case 2:
                    return "buy_btn_1PsYYHP39O7fMWAZL61XSguJ"
                case 3:
                    return "buy_btn_1PsYQuP39O7fMWAZaDuRJVTg"
                default:
                    //console.log("Error: got " + buildingId);
                    return "";
            }
        }
    };

    return (
        <stripe-buy-button
            buy-button-id={getButtonId()}
            publishable-key={process.env.REACT_APP_STRIPE_KEY}
        >
        </stripe-buy-button>
    );
};

export default OnePayment;

/*
//Spyglas R once
buy_btn_1PsYVmP39O7fMWAZlX3QY25p
//Spyglas RP once
buy_btn_1PsYW6P39O7fMWAZJkemFBAe




//Normas R once
buy_btn_1PsYXeP39O7fMWAZfXIsjFEs
//Normas RP once
buy_btn_1PsYYHP39O7fMWAZL61XSguJ



//Single R Tree once
buy_btn_1PsYQNP39O7fMWAZW5i1dZ8d
//Single RP Tree once
buy_btn_1PsYQuP39O7fMWAZaDuRJVTg
*/