// frontend/src/components/AuthForm.js

import React, { useState } from 'react';
import './Authform.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AuthForm = ({ onSuccess }) => {
    const navigate = useNavigate();
    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const [error, setError] = useState('');

    const [formType, setFormType] = useState('login'); // 'login' or 'register'
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        buildingId: '',
        apt: '',
        password: '',
    });

    const buildings = [
        { id: '1', name: 'Spyglas' },
        { id: '2', name: 'Normas' },
        { id: '3', name: 'Single Tree' },

    ];

    const toggleFormType = () => {
        setFormType(prevType => (prevType === 'login' ? 'register' : 'login'));
        setError('');
    };

    const handleChange = (e) => {
        //console.log(e);
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(formData);
        try {
            const endpoint = formType === 'login' 
                ? `${process.env.REACT_APP_API_URL}/api/user/login` 
                : `${process.env.REACT_APP_API_URL}/api/user/register`;
            const response = await axios.post(endpoint, formData);
            
            if (response.status === 200 || response.status === 201) {
                onSuccess(response.data);
                await sleep(500);
                navigate("/payment"); // Navigate to the payment page
            }
        } catch (error) {
            setError("Error: "+JSON.stringify(error.response.data.message));
        }
    };

    return (
        <div>
        <br/>
        <br/>
        <br/>
        <div className="frm">
            <h2>{formType === 'login' ? 'Login' : 'Register'}</h2>
            <form onSubmit={handleSubmit}>
                {formType === 'register' && (
                    <div>
                    <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                {formType === 'register' && (
                    <div>
                    <div className="form-group">
                        <label htmlFor="phone">Phone Number (optional)</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                    <label htmlFor="buildingId">Building</label>
                        <select
                            id="buildingId"
                            name="buildingId"
                            value={formData.buildingId}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Building</option>
                            {buildings.map((building) => (
                                <option key={building.id} value={building.id}>
                                    {building.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="apt">Apt</label>
                        <input
                            type="number"
                            id="apt"
                            name="apt"
                            value={formData.apt}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button className="btn" type="submit">{formType === 'login' ? 'Login' : 'Register'}</button>
                <br/>
                <button onClick={toggleFormType} className="btn" type="button">{formType === 'login' ? 'Register instead' : 'Login instead'} </button>
            </form>
            <br/>
            {error && <p className="error-message">{error}</p>}
        </div>
        <br/>
        <br/>
        </div>
    );
};

export default AuthForm;
