import './TaC.css';
import { Link } from 'react-router-dom';

const TaC = () => {
    return (
        <div className="tac-container">
            <h1>Terms and Conditions</h1>
            <hr />

            <h2>0. Introduction</h2>
            <p>{<>
            Welcome to Cathy's Computer! Please carefully read and understand these Terms and Conditions before using this 
            platform, but know you agree to them by using our service, even if you haven't read them. Thank you for reading 
            this - stay <Link to="https://www.biggestlieonline.com/">safe</Link> out there! By using this service, 
            you agree to the following terms:    
            </>}</p>

            <h2>1. Agreement Overview</h2>
            <p>{<>
            This agreement outlines the terms and conditions for our service. It's important to note that we are not legal professionals, 
            and this agreement serves as a mutual understanding. While prima facie it seems unnecessary to us to have this document, we 
            want to ensure no one is taken advantage of. All parties agree to be transparent, communicative, and cooperative in good faith 
            to facilitate an environment of trust.
            </>}</p>
 
            <h2>2. Responsibility</h2>
            <p>{<>
            You will always bear sole responsibility for your interactions and needs with the management. By using our website, you 
            choose us as a method for doing so in the capacity we are designed to do. We are not and do not represent the management. 
            We are and do exist as a convenient pipeline for interactions between you and them.
            </>}</p>

            <h2>3. Payment Process</h2>
            <p>{<>
            We use a highly secure and reputable payment processor to use online transacations. Processing cards, like all transactional 
            software, costs fees (around 3.5%) and we pass these to you. We are committed to keeping the fees below 4%, regardless of our 
            various costs so that paying online is viable option for everyone. If we receive payment by bank details, we'll give you 
            10$ back with the receipt. Bank processing fees are that much lower than card fees! We do not store or sell any of your 
            information in payment, and neither does the processor without your express consent. Upon receiving your payment, we commit to 
            forwarding it to the relevant recipient (e.g., Cathy). She signs saying you have paid what you owe, and we send this back to 
            you. These receipts will be sent to you no later than the 6th of the month following the one you paid or no later than 6 business days 
            after you paid, whichever is later. Note that for payments made on or after the 26th of the month, the late fee must be attached.
            </>}</p>

            <h2>4. Payment Errors</h2>
            <p>{<>
            If you paid incorrectly, we will pay Cathy what we have as a credit on what you owe and send you a receipt. If you alter your 
            payment in a way that causes us fees, you agree to pay for them - please contact us before so we can help you! If you paid 
            correctly but we get it wrong or cannot deliver your funds to Cathy, we will refund you and pay for any fees this may cause.
            </>}</p>

            <h2>5. Maintenance Requests</h2>
            <p>{<>
            When you send a maintenance request, we give it to Cathy and send a picture of her reception of it for your own records and 
            follow-up. When you submit a request, it will be given to Cathy no later than the 6th of the month following the one you 
            submit or no later than 3 business days after you submit, whichever is sooner.
            </>}</p>

            <h2>6. Contract</h2>
            <p>{<>
            When you are selling your contract, you agree to make personally identifiable information that you have given us 
            available to the public so persons may contact you. Our job is to connect people selling and buying - it is yours 
            to get it signed and done!
            </>}</p>

            <h2>7. Notices</h2>
            <p>{<>
            We do our best to give out the notices as soon as we are aware of them, but we give no guarantees that they will be given 
            in the timeframe you may need them. Relying strictly on our notices carries the small risk of missing out on information - 
            Please keep yourself well informed!
            </>}</p>

            <h2>8. Privacy</h2>
            <p>{<>
            We are committed to not selling or sharing your personal information, and we expect you to do the same for us. 
            Please see the "Contract" and "Payment Process" sections for more details.
            </>}</p>

            <h2>9. Acknowledgment</h2>
            <p>{<>
            You acknowledge that you are engaging with this service of your own free will and choice. By using this website or paying 
            through this platform, you agree to these Terms and Conditions, even if you haven't read them.
            </>}</p>

            <h2>10. Legal</h2>
            <p>{<>
            We reserve the right to change the Terms and Conditions at any time. All the Terms and Conditions are enforceable, void, 
            or conditional in any portion and combination, at our discretion.
            </>}</p>

            <h2>11. Contact</h2>
            <p>{<>
            If you have suggestions, questions, concerns, feedback, or life hacks, please <Link to='/support'>contact</Link> us at 
            cathysfirstcomputer@gmail.com.
            </>}</p>

            <hr />
            <br/>
            <p>Thank you for using and making this project possible, we are honored! Be excellent to eachother.</p>
        </div>
    );
};

export default TaC;
