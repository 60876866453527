import React from 'react';

const LatePayment = ({ parks, buildingId }) => {

    const getButtonId = () => {
        if (!parks) {
            switch (buildingId) {
                case 1:
                    return "buy_btn_1PsYjCP39O7fMWAZD7UKLods"
                case 2:
                    return "buy_btn_1PsYhhP39O7fMWAZRYRQdxSu"
                case 3:
                    return "buy_btn_1PsYibP39O7fMWAZx9TFbcb9"
                default:
                    //console.log("Error: got " + buildingId);
                    return "";
            }
        } else {
            switch (buildingId) {
                case 1:
                    return "buy_btn_1PsYWZP39O7fMWAZ2NnfwXqv"
                case 2:
                    return "buy_btn_1PsXreP39O7fMWAZ7q2VuTdC"
                case 3:
                    return "buy_btn_1PsYRbP39O7fMWAZl6kG5QMx"
                default:
                    //console.log("Error: got " + buildingId);
                    return "";
            }
        }
    };

    return (
        <stripe-buy-button
            buy-button-id={getButtonId()}
            publishable-key={process.env.REACT_APP_STRIPE_KEY}
        >
        </stripe-buy-button>
    );
};

export default LatePayment;

/*
//Spyglas RL
buy_btn_1PsYjCP39O7fMWAZD7UKLods
//Spyglas RPL
buy_btn_1PsYWZP39O7fMWAZ2NnfwXqv




//Normas RL
buy_btn_1PsYhhP39O7fMWAZRYRQdxSu
//Normas RPL
buy_btn_1PsXreP39O7fMWAZ7q2VuTdC




//Single Tree RL
buy_btn_1PsYibP39O7fMWAZx9TFbcb9
//Single Tree RPL
buy_btn_1PsYRbP39O7fMWAZl6kG5QMx
*/