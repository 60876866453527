import React from 'react';

const SingleTreeContractPages = () => {
    return (
        <div className="contract-pages">
            <img src={`/assets/contracts/singleTree/page1.jpg`} alt="Contract Page 1" />
            <img src={`/assets/contracts/singleTree/page2.jpg`} alt="Contract Page 2" />
            <img src={`/assets/contracts/singleTree/page3.jpg`} alt="Contract Page 3" />
            <img src={`/assets/contracts/singleTree/page4.jpg`} alt="Contract Page 4" />
            <img src={`/assets/contracts/singleTree/page5.jpg`} alt="Contract Page 5" />
            <img src={`/assets/contracts/singleTree/page6.jpg`} alt="Contract Page 6" />
            <img src={`/assets/contracts/singleTree/page7.jpg`} alt="Contract Page 7" />
            <img src={`/assets/contracts/singleTree/page8.jpg`} alt="Contract Page 8" />
        </div>
    );
}

export default SingleTreeContractPages;