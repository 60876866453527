import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode
import './Maintenance.css';

const Maintenance = () => {
    const [issue, setIssue] = useState('');
    const [success, setSuccess] = useState('');
    const [requests, setRequests] = useState([]);
    const [error, setError] = useState('');
    const [userId, setUserId] = useState(null);
    const [expandedRequest, setExpandedRequest] = useState(null); // State for accordion

    useEffect(() => {
        const token = localStorage.getItem('cathyToken');
        if (token) {
            const decodedToken = jwtDecode(token);
            setUserId(decodedToken.user.id);
        }

        const fetchRequests = async () => {
            if (userId) {
                try {
                    const endpoint = `${process.env.REACT_APP_API_URL}/api/maintenance/user/${userId}`;
                    const response = await axios.get(endpoint);
                    setRequests(response.data.reverse()); // Ensure most recent requests are first
                    setSuccess('');
                    setError('');
                } catch (err) {
                    setSuccess('');
                    setError('Failed to fetch requests: '+err.message);
                }
            }
        };

        fetchRequests();
    }, [userId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (userId) {
                const endpoint = `${process.env.REACT_APP_API_URL}/api/maintenance`;
                await axios.post(endpoint, { userId, issue });
                //console.log(sendResponse);
                setIssue(''); // Clear the input field
                
                // Fetch updated list of requests
                const updateResponse = await axios.get(endpoint + '/user/' + userId);
                setRequests(updateResponse.data.reverse());
                setError('');
                setSuccess('Your request has been submitted!');
            } else {
                setSuccess('');
                setError('You aren\'t logged in.')
            }
        } catch (err) {
            setSuccess('');
            setError('Failed to submit request: '+err.message);
        }
    };

    const handleDelete = async (id) => {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}/api/maintenance/${id}`;
            await axios.delete(endpoint);
            setRequests(requests.filter((request) => request.id !== id)); // Remove the deleted request from the state
            setError('');
            setSuccess('Successfully deleted request');
        } catch (err) {
            setSuccess('');
            setError('Failed to delete request');
        }
    };

    const toggleRequest = (index) => {
        setExpandedRequest(expandedRequest === index ? null : index);
        setSuccess('');
        setError('');
    };

    return (
        <div>
        <br/>
        <br/>
        <br/>
            <div className="frm">
                <h2>Submit a Maintenance Request</h2>
                <form onSubmit={handleSubmit}>
                    <textarea
                        placeholder="Describe the issue"
                        value={issue}
                        onChange={(e) => setIssue(e.target.value)}
                        required
                    ></textarea>
                    <button className="btn" type="submit">Submit Request</button>
                </form>
                {success && <p className="success-message">{success}</p>}
                {error && <p className="error-message">{error}</p>}
            </div>
            <br />
            <ul className="my-accordion">
                {requests.length > 0 ? (
                    requests.map((request, index) => (
                        <li key={index} className="my-accordion-item">
                            <div className="my-accordion-title" onClick={() => toggleRequest(index)}>
                                {new Date(request.createdAt).toLocaleDateString()} - {request.issue.substring(0, 16)}...
                                    <button className="btn-small" onClick={(e) => {e.stopPropagation(); handleDelete(request.id);}}>Delete</button>
                            </div>
                            {expandedRequest === index && (
                                <div className="my-accordion-content">
                                    <p>{request.issue}</p>
                                </div>
                            )}
                        </li>
                    ))
                ) : (
                <li className="my-accordion-item">
                    <div className="my-accordion-title" onClick={() => {setError(''); setSuccess('');}}>
                        {new Date().getMonth()} {new Date().getDate()} {new Date().getFullYear()} - No requests made.
                    </div>
                </li>
                )}
            </ul>
        </div>
    );
};

export default Maintenance;
