import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Contract.css';
import SpyglasContractPages from '../components/SpyglasContractPages';
import NormaContractPages from '../components/NormaContractPages';
import SingleTreeContractPages from '../components/SingleTreeContractPages';
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode

const Contract = () => {
    const [contracts, setContracts] = useState({});
    const [buildingId, setBuildingId] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchContracts = async () => {
            try {
                const endpoint = `${process.env.REACT_APP_API_URL}/api/user/selling`;
                const response = await axios.get(endpoint);
                setContracts(response.data);
                //console.log(JSON.stringify(contracts));
            } catch (err) {
                setError('Failed to fetch contracts: ' + err.message);
            }
        };

        const fetchUserBuilding = async () => {
            const token = localStorage.getItem('cathyToken');
            if (token) {
                const decodedToken = jwtDecode(token);
                setBuildingId(decodedToken.user.buildingId);
            }
        };

        fetchContracts();
        fetchUserBuilding();
    }, []);

    const renderContractPages = () => {
        switch(buildingId){
            case 1:
                return <SpyglasContractPages />
            case 2:
                return <NormaContractPages />
            case 3:
                return <SingleTreeContractPages />    
            default:
                return <p>No contract found for your building. Are you logged in?</p>
        }
    };

    return (
        <div className="contracts-container">
            <br/>
            <h2 className='contract-title'>Selling Contracts</h2>
            <br/>
            {error && <p>{error}</p>}
            {Object.keys(contracts).map((building) => (
                <div key={building} className="contract-section">
                    <h2>{building}</h2>
                    {contracts[building].length > 0 ? (
                        contracts[building].map((user, index) => (
                            <p key={index}>{user.firstName + " " + user.lastName.substring(0,1).toUpperCase() + "."} - {user.phone + " " + user.email}</p>
                        ))
                    ) : (
                        <p>No contracts selling.</p>
                    )}
                </div>
            ))}

            <h2 className='contract-title'>Your Contract</h2>
            {renderContractPages()}
        </div>
    );
};

export default Contract;
